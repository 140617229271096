const analytics = {
  debugMode:
    process.env.APP_ENV === 'prd' || process.env.APP_ENV === 'acc'
      ? false
      : true,

  sendPageView: (
    pageName: string,
    pageCategory: string[],
    status: number,
    userId?: string,
    locale?: string,
  ) => {
    const udata = window.udata || []
    const data = {
      event_name: 'page navigation',
      page_language: locale?.toUpperCase() ?? 'NL',
      page_name: pageName,
      page_category: pageCategory,
      page_status: status,
      page_template: 'other',
      user_id: userId ?? '',
      user_logged: 'yes',
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('Page view', data)
    }
  },

  sendUnauthorizedPageView: (
    pageName: string,
    pageCategory: string[],
    status: number,
    userId?: string,
    locale?: string,
  ) => {
    const udata = window.udata || []
    const data = {
      event_name: 'page navigation',
      page_language: locale?.toUpperCase() ?? 'NL',
      page_name: pageName,
      page_category: pageCategory,
      page_status: status,
      page_template: 'error unauthorised',
      user_id: userId ?? '',
      user_logged: 'yes',
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('sendUnauthorizedPageView', data)
    }
  },

  sendFormSubmitSuccessful: (
    name: 'change my company details' | 'add fuel card',
  ) => {
    const udata = window.udata || []
    const data = {
      event_name: 'form submit',
      form_name: name,
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('sendFormSubmitSuccessfull: ', data)
    }
  },

  sendFormSubmitWithError: (
    name: 'change my company details' | 'add fuel card',
    error: undefined | string,
  ) => {
    const udata = window.udata || []
    const data = {
      event_name: 'form error',
      form_name: name,
      form_error_message: error,
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('sendFormSubmitSuccessfull: ', data)
    }
  },

  // sendFormView: (name: 'change my company details' | 'add fuel card') => {
  //   const udata = window.udata || []
  //   const data = {
  //     event_name: 'form start',
  //     form_name: name,
  //   }
  //   udata.push(data)
  //   if (analytics.debugMode) {
  //     console.log('sendFormSubmitSuccessfull: ', data)
  //   }
  // },

  sendViewInvoiceEvent: () => {
    const udata = window.udata || []
    const data = {
      event_name: 'interaction',
      interaction_name: 'click: view invoice',
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('sendViewInvoiceEvent: ', data)
    }
  },

  sendBulkDownloadInvoiceEvent: (
    amountSuccessful: number,
    amountAttempted: number,
  ) => {
    const udata = window.udata || []
    const data = {
      event_name: 'interaction',
      interaction_name: 'click: bulk download invoice',
      interaction_values1: amountSuccessful,
      interaction_values2: amountAttempted,
      interaction_details1: `${amountSuccessful}`,
      interaction_details2: `${amountAttempted}`,
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('sendBulkDownloadInvoiceEvent: ', data)
    }
  },

  sendDownloadInvoiceErrorEvent: (
    amountUnsuccessful: number,
    amountAttempted: number,
    errorMessage: string,
  ) => {
    const udata = window.udata || []
    const data = {
      event_name: 'interaction',
      interaction_name: 'impression: download invoice error',
      interaction_values1: amountUnsuccessful,
      interaction_values2: amountAttempted,
      interaction_details1: `${amountUnsuccessful}`,
      interaction_details2: `${amountAttempted}`,
      interaction_details3: errorMessage,
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('sendDownloadInvoiceErrorEvent: ', data)
    }
  },

  sendUserLogInEvent: (isLoggedIn: string, userId?: string) => {
    const udata = window.udata || []
    const data = {
      event_name: 'user login',
      user_id: userId ?? '',
      user_logged: isLoggedIn.toLowerCase(),
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('sendUserLogInEvent: ', data)
    }
  },

  sendUserLoggedOutEvent: (isLoggedIn: string, userId?: string) => {
    const udata = window.udata || []
    const data = {
      event_name: 'user logout',
      user_id: userId ?? '',
      user_logged: isLoggedIn.toLowerCase(),
    }
    udata.push(data)
    if (analytics.debugMode) {
      console.log('sendUserLoggedOutEvent: ', data)
    }
  },
}

export default analytics
